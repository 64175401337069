.dashboard .card {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  h5 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
  }
  
  ul {
    list-style: none;
    padding-left: 0;
  }
  
  ul li {
    margin: 5px 0;
  }
  
  a {
    text-decoration: none;
    color: #007bff;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .table th,
  .table td {
    text-align: center;
    vertical-align: middle;
  }

  .contact-list .row {
    padding: 4px 0;
    font-size: 0.55rem; /* Decreased font size */
  }
  
  .contact-list .row:last-child {
    border-bottom: none;
  }
  
  .contact-list .fw-bold {
    font-size: 0.7rem; /* Slightly larger for names */
  }
  
  .contact-list .text-muted {
    font-size: 0.65rem;
  }
  
  .contact-list .badge {
    font-size: 0.55rem; /* Smaller font for status badges */
    padding: 4px 8px;
  }
  
/* Overlay */
.subscription-popup-overlay {
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  
  /* Popup Content */
  .subscription-popup-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 800px;
    position: relative;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
  }
  
  /* Close Button */
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  /* Title & Note */
  .popup-title {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .popup-note {
    text-align: center;
    color: #666;
    font-size: 0.9rem;
    margin-bottom: 20px;
  }
  
  .popup-note a {
    color: #0ea5e9;
    text-decoration: underline;
  }
  
  /* Toggle Buttons */
  .toggle-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .toggle-btn {
    background: #e0e0e0;
    border: none;
    padding: 8px 20px;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .toggle-btn.active {
    background: #0ea5e9;
    color: white;
  }
  
  /* Plans Container */
  .plans-container {
    display: flex;
    justify-content: space-around;
    gap: 15px;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .plan-card {
    background: #f9fafb;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    flex: 1;
    min-width: 200px;
  }
  
  .plan-card.popular {
    border: 2px solid #0ea5e9;
  }
  
  .popular-badge {
    background: #0ea5e9;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 0.8rem;
    margin-left: 5px;
  }
  
  .plan-price {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .plan-desc {
    color: #666;
    font-size: 0.9rem;
    margin-bottom: 10px;
  }
  
  .plan-card ul {
    list-style: none;
    padding: 0;
    margin: 10px 0;
  }
  
  .plan-card ul li {
    margin-bottom: 5px;
  }
  
  .plan-btn {
    background: #0ea5e9;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Footer */
  .popup-footer {
    text-align: center;
    margin-top: 20px;
  }
  
  .contact-btn {
    background: #16a34a;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  
.container {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h3 {
    margin-bottom: 20px;
}

.table th {
    background-color: #f8f9fa;
    font-weight: 600;
}

.table td {
    vertical-align: middle;
}

.table .badge {
    font-size: 0.85rem;
}

.pagination .page-item .page-link {
    color: #007bff;
}

.pagination .page-item.active .page-link {
    background-color: #007bff;
    border-color: #007bff;
}

.template-preview {
    background-color: #ECE5DD;
  

    padding: 20px;
    width: 300px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    position: relative;
}

.template-header-image {
    width: 200px;
    
    height: 200px;
    border-radius: 8px 8px 0 0;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.template-header-image {
    max-width: 200px;
    max-height: 200px;
    object-fit: cover;
}

.template-body {
    background: #fff;
    padding: 15px;
    border-radius: 0 0 8px 8px;
    border-radius: 8px 8px 8px 0;
    margin-top: -4px;
}

.template-body p {
    margin: 0;
    font-size: 14px;
}

.template-footer {
    font-size: 12px;
    color: gray;
    text-align: right;
    margin-top: 10px;
}
/* WhatsApp-style Buttons */
.template-buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
}

.template-buttons button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    border: none;
    border-top: 1px solid #d1d6db;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    color: #075e54; /* WhatsApp Primary Color */
    gap: 8px;
    width: 100%;
}

.template-buttons button:hover {
    background-color: #f5f5f5;
}

/* Icon Styling */
.template-buttons button i {
    font-size: 16px;
    color: #128c7e; /* WhatsApp Icon Color */
}


.cardd-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin: 20px;
  }
  
  .cardd {
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .cardd h3 {
    margin: 10px 0;
  }
  
  .cardd p {
    font-size: 14px;
    color: #666;
  }
  
  .cardd button {
    margin-top: 10px;
    padding: 8px 16px;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popup {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
  }
  
  .popup label {
    display: block;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .popup input,
  .popup textarea {
    width: 100%;
    margin-top: 5px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .popup-actions {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }
  